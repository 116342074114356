<template>
	<div class="container" style="min-height: 100%; padding-bottom: 100px;">
		<el-descriptions title="用户信息" icon="el-icon-user"  row="2">
			<el-descriptions-item label="供应商名称"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="统一社会信用代码"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="供应商简称"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="企业性质"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="法人代表"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="法人手机"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="注册资本"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="企业网址"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="营业期限"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="纳税人资格"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="纳税人识别号"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="公司地址"><el-input>11111111</el-input></el-descriptions-item>
			<el-descriptions-item label="经营范围"><el-input>11111111111111</el-input></el-descriptions-item>
			<el-descriptions-item label="用户名"><el-input>11111111</el-input></el-descriptions-item>
		</el-descriptions>
	</div>
</template>

<script>
	export default {
		props: [],
		components: {},
		data() {
			return {

			}
		},
		watch: {},
		computed: {},
		beforeCreate() {},
		created() {},
		beforeMount() {},
		mounted() {},
		beforeUpdate() {},
		updated() {},
		destoryed() {},
		methods: {
			request() {},
			hello() {
				alert("来自预设逻辑代码的问候")
			},
		},
		fillter: {},
	}
</script>

<style scoped>
	.container {}
</style>
